@tailwind base;
@tailwind components;
@tailwind utilities;

#app{
  background-color:rgb(173, 202, 210);
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.tag-input-wrapper {
  display: flex;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  flex-wrap: wrap;
  background-color: #fff;
}

.tag-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.tag {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 15px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.tag button {
  background: none;
  border: none;
  margin-left: 10px;
  cursor: pointer;
  color: #555;
}

.tag-input-wrapper input {
  border: none;
  outline: none;
  flex-grow: 1;
  padding: 5px;
  font-size: 1rem;
}

.tag-input-wrapper:focus-within {
  border-color: #000;
}

@layer utilities {
  @keyframes marquee {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-200%);
    }
  }
  .animate-marquee {
    animation: marquee var(--duration, 10s) linear infinite;
    display: flex;
    width: calc(200%);
  }
}
